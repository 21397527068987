<template>
  <v-card class="pa-2">
    <v-form ref="form">
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="categoria.nombreCategoria"
            label="Nombre"
            :rules="[rules.req]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-color-picker
            label="Color"
            hide-details="auto"
            v-model="categoria.color"
          ></v-color-picker>
        </v-col>
        <v-col cols="12">
          <v-card-actions class="d-flex">
						<v-spacer></v-spacer>
						<v-btn v-if="categoria.idCategoriaEquipo" @click.stop="$emit('reload')">
							<v-icon left>mdi-close</v-icon>
												Cancelar
						</v-btn>
						<v-btn @click.stop="save" color="primary">
							<v-icon left>mdi-content-save</v-icon>
							{{ categoria.idCategoriaEquipo ? "Actualizar" : "Crear" }}
						</v-btn>
					</v-card-actions>
        </v-col>
      </v-row>
    </v-form>

    <ConfirmDialog ref="confirm" />

  </v-card>
</template>

<script>
import { req } from "@/utils/validations.js";

export default {
  props: {
    value: Object,
  },
	components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
	},
  data() {
    return {
      categoria: {
        ...this.value,
        range: [this.value.start, this.value.end].filter((x) => x),
      },
      rules: {
        req,
      },
    };
  },
  methods: {
    async save() {
      if (!this.$refs.form.validate()) return;

			const confirmation = await this.$refs.confirm.open(
				`Categoría: ${this.categoria.nombreCategoria}`,
        `¿Estás seguro de que quieres ${this.categoria.idCategoriaEquipo ? "actualizar" : "crear"} la categoria?`,
        { color: "primary" }
      );
      if (!confirmation) return;

      await axios({
        method: this.categoria.idCategoriaEquipo ? "PUT" : "POST",
        url: `/categorias_equipo/${String(
          this.categoria.idCategoriaEquipo ? this.categoria.idCategoriaEquipo : ""
        )}`.replace(/\/+$/, ''),
        data: {
          color: this.categoria.color.hex,
          nombreCategoria: this.categoria.nombreCategoria,
        },
      });

      this.$emit("reload");
    },
  },
};
</script>

<style>
</style>